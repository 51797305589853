import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import DemographicsFormConfig from "./demographics-form.config.json";

export const DemographicsForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  id: 0,
  index: 2,
  title: "Demographics",
  shortTitle: "Demographics",
  slug: "demographics",

  description: "We aim to make our Fellowships diverse in the broadest sense of the word.",
  form: {
    id: "",
    name: "",
    durationEstimateInMinutes: 2,
    formDefinition: DemographicsFormConfig,
  },
};
