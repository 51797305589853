import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import ContactDetailsFormConfig from "./contact-details-form.config.json";

export const ContactDetailsForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  id: 0,
  index: 0,
  title: "Contact details",
  shortTitle: "Contact details",
  slug: "contact-details",
  description: "Easy questions first, tell us who you are and how you know about us.",
  form: {
    id: "",
    name: "",
    durationEstimateInMinutes: 2,
    formDefinition: ContactDetailsFormConfig,
  },
};
