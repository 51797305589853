import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import ODSApplicationFormConfig from "./ods-application-form.config.json";

export const ODSApplicationForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  index: 1,

  id: 64,
  title: "Scale Questions",
  shortTitle: "Scale Questions",
  slug: "program-questions",

  description: "Tell us more about your company, goals for the future and your interest in joining On Deck Scale.",
  form: {
    id: "ods-company-details",
    name: "ODS Founder Goals & Company (2)",
    durationEstimateInMinutes: 2,
    formDefinition: ODSApplicationFormConfig,
  },
};
