import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import ODAApplicationFormConfig from "./oda-application-form.config.json";

export const ODAApplicationForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  index: 1,

  id: 0,
  title: "Angel Questions",
  shortTitle: "Angel Questions",
  slug: "program-questions",

  description:
    "Tell us a little about your history and plans for the future. Please emphasize aspects of your experience relevant to this Fellowship.",
  form: {
    id: "",
    name: "",
    durationEstimateInMinutes: 2,
    formDefinition: ODAApplicationFormConfig,
  },
};
