import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import ODXShoulderTapFormConfig from "./odx-shoulder-tap-form.config.json";

export const ODXShoulderTapForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  index: 0,

  id: 0,
  title: "ODX Shoulder Tap",
  shortTitle: "ODX Shoulder Tap",
  slug: "odx-shoulder-tap",

  description: "Make sure you have the candidate's full name, email, and LinkedIn. \n\nShould take approx 2 min.",
  form: {
    id: "",
    name: "",
    durationEstimateInMinutes: 2,
    formDefinition: ODXShoulderTapFormConfig,
  },
};
