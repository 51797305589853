import type { ApplyCohortOnlyGqlCodegen } from "features/program-applications/utils";

/**
 * This function returns the currently active cohorts for apply, where currently active is defined as:
 * - applicationsStartedAt is in the past
 * - applicationsClosedAt is the future
 *
 * note: Since both params are optional, we treat as active if they're not provided
 *
 * @param cohorts An array of objects containing applicationsStartedAt & applicationsClosedAt properties
 * @returns A list of active cohorts
 */
export const getActiveCohorts = <
  // note (richard): Picking from Gql as the most basic type; works also with ApplyCohortYup
  Cohort extends Partial<Pick<ApplyCohortOnlyGqlCodegen, "applicationsClosedAt" | "applicationsStartedAt">>,
>(
  cohorts: Array<Cohort>,
) => {
  return cohorts.filter(
    (cohort) =>
      // if start date is supplied, it must be in the past
      (cohort.applicationsStartedAt ? new Date(cohort.applicationsStartedAt) < new Date() : true) &&
      //                                                                                     ^
      // if closing date is supplied, it must be in the future
      (cohort.applicationsClosedAt ? new Date(cohort.applicationsClosedAt) > new Date() : true),
    //                                                                                     ^ this ensures that if close date isn't supplied cohort can still be active
    //                                                                                       If it was false, it would change the behavior into _requiring_ the start and close dates
  );
};
