import type { ApplyFormOnlyGqlCodegen, ApplyStepOnlyGqlCodegen } from "features/program-applications/utils";

import ODFNewApplicationFormConfig from "./odf-new-application-form.config.json";

export const ODFNewApplicationForm: ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen } = {
  index: 1,

  id: 0,
  title: "Founder Questions",
  shortTitle: "Founder Questions",
  slug: "program-questions",

  description: "Tell us more about your founder trajectory and your interest in joining On Deck Founders.",
  form: {
    id: "odf-founder-details",
    name: "ODF Founder Questions",
    durationEstimateInMinutes: 5,
    formDefinition: ODFNewApplicationFormConfig,
  },
};
